// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import vueI18n from '@/plugins/vue-i18n'
import vueGdpr from '@/plugins/vue-gdpr'
import templates from '@/utils/templates'
import App from './App'

Vue.config.productionTip = false;
(() => {
  const init = function (
    {
      id = '#wc-gdpr-widget',
      locale = 'en',
      name = '',
      linkPolicy = '',
      customCookieMessage = '',
      template = { name: 'default' },
      scripts = [],
      inject = { scripts: {}, categories: {}, policies: {} },
      dbCookieId = '__wc_gdpr_db',
      lmCookieId = '__wc_gdpr_lm',
      expires = 365,
      expiresLm = 14,
      policies = []
    } = {}
  ) {
    const locales = ['fr', 'en']
    locale = typeof locale === 'string' && locales.indexOf(locale) > -1 ? locale : 'en'
    if (typeof template === 'string') {
      template = { name: template }
    }
    template.name = templates[template.name] ? templates[template.name] : 'default'
    const app = {
      el: id,
      components: { App },
      template: '<App/>'
    }
    Vue.prototype.$template = template
    Vue.prototype.$locale = locale
    const gdprOpt = { scripts, inject, dbCookieId, lmCookieId, expires, expiresLm, locales, currentLocale: locale, policies, name, customCookieMessage, linkPolicy }
    vueGdpr({ app, opt: gdprOpt })
    vueI18n({ app, locale })
    /* eslint-disable no-new */
    new Vue(app)
    return app.gdpr.executePublicFn.bind(app.gdpr)
  }
  let wcgdprFns
  const wcgdpr = function (...args) {
    const fnName = args.shift()
    // console.log(fnName)
    if (fnName === 'init') {
      wcgdprFns = init(...args)
      return
    }
    if (!wcgdprFns) {
      console.warn('you must call the init function before any call')
      return true
    }
    wcgdprFns(fnName, ...args)
  }
  const globalName = typeof window.WcGdprObject === 'string' ? window.WcGdprObject : '_wcgdpr'
  const pushedFn = typeof window[globalName] === 'function' && Array.isArray(window[globalName].a) ? window[globalName].a : []
  window[globalName] = wcgdpr
  pushedFn.some((args) => {
    wcgdpr(...args)
  })
})()
