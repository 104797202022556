export default {
  id: 'webcomChat',
  name: 'WEBCOM Chat',
  category: 'others',
  policy: {
    fr: '<p><strong>WEBCOM Chat :</strong></p><p>Le chat vous permet de converser avec l\'hôtel pour avoir les informations que vous souhaités. Vos données sont concervées 2 ans</p>'
  },
  linkWebsite: (lang) => {
    return 'https://www.agencewebcom.com/solution-webcom360'
  },
  js: async (params, locale) => {
    const chatJs = require(`@/utils/chat/chat-v${params.version || 1}`).default
    chatJs(params, locale)
  },
  cookies: ['__webc_chat_id']
}
