import Cookies from 'vue-cookies'
import Vue from 'vue'
import categories from '@/utils/categories'
import scriptFiles from '@/utils/scripts'
import policiesTrans from '@/utils/policies'
import { getDefaultValue } from '@/utils/tools'
Vue.use(Cookies)

class VueGdpr {
  constructor ({ scripts, inject, dbCookieId, lmCookieId, expires, expiresLm, locales, currentLocale, policies, name, customCookieMessage, linkPolicy }) {
    const self = this
    this.options = { scripts, inject, dbCookieId, lmCookieId, expires, expiresLm, locales, name, linkPolicy }
    this.customCookieMessage = customCookieMessage
    expiresLm = 1000 * 60 * 60 * 24 * expiresLm
    const db = Cookies.get(dbCookieId) || {}
    const lm = Cookies.get(lmCookieId) || 0

    let lmExpired = false
    const status = { accepted: 0, denied: 0 }
    const scriptsCategories = {}
    const messages = {}
    locales.forEach((locale) => {
      messages[locale] = {
        scripts: {},
        categories: {},
        policies: {}
      }
      if (typeof policies !== 'string') {
        policies.forEach((policy, index) => {
          if (typeof inject.policies === 'object' && inject.policies[policy]) {
            policiesTrans[policy] = inject.policies[policy]
          } else if (!policiesTrans[policy]) {
            policies.splice(index, 1)
            return
          }
          messages[locale].policies[policy] = policiesTrans[policy][locale] || getDefaultValue(policiesTrans[policy], '', 'en')
        })
      } else {
        messages[locale].policies = policies
      }
    })

    const todayTime = (new Date()).getTime()
    const usedScripts = []
    const usedScriptsIds = {}

    scripts.forEach(({ id, opt = {} }, idx) => {
      if (!id) {
        return
      }
      let returningResult = null
      const injectedScript = inject.scripts[id]
      if (injectedScript) {
        if (!scriptFiles[id]) {
          returningResult = { script: injectedScript, opt }
        } else {
          const scriptFileName = scriptFiles[id]
          const fileScript = require(`@/scripts/${scriptFileName}.js`).default
          returningResult = { script: { ...fileScript, ...injectedScript }, opt }
        }
      } else if (scriptFiles[id]) {
        const scriptFileName = scriptFiles[id]
        returningResult = {
          script: require(`@/scripts/${scriptFileName}.js`).default,
          opt
        }
      } else {
        return
      }
      const script = returningResult.script

      if (!script.category) {
        script.category = 'others'
      }

      let injectedCat = inject.categories[script.category]
      injectedCat = injectedCat && typeof injectedCat === 'object' ? injectedCat : null
      if (injectedCat) {
        const defCat = categories[script.category] ? categories[script.category] : {}
        categories[script.category] = { ...defCat, ...injectedCat }
        if (!categories[script.category].id) {
          categories[script.category].id = script.category
        }
        delete inject.categories[script.category]
      }

      if (!categories[script.category]) {
        script.category = 'others'
      }
      const defaultScriptName = getDefaultValue(script.name, script.id, 'en')
      const defaultPolicyText = getDefaultValue(script.policy, '', 'en')
      const defaultCatName = getDefaultValue(categories[script.category].name, script.category, 'en')
      if (script.policy && typeof policies !== 'string') {
        policies.push(script.id)
      }
      locales.forEach((locale) => {
        messages[locale].scripts[script.id] = typeof script.name === 'object' && script.name[locale] ? script.name[locale] : defaultScriptName
        if (script.policy && typeof policies !== 'string') {
          messages[locale].policies[script.id] = typeof script.policy === 'object' && script.policy[locale] ? script.policy[locale] : defaultPolicyText
        }
        if (!messages[locale].categories[script.category]) {
          messages[locale].categories[script.category] = typeof categories[script.category].name === 'object' && categories[script.category].name[locale] ? categories[script.category].name[locale] : defaultCatName
        }
      })

      if (typeof db[script.id] === 'undefined') {
        db[script.id] = null
      }

      if (db[script.id] === false) {
        status.denied++
      } else if (db[script.id]) {
        status.accepted++
      }

      if (!lmExpired && !db[script.id] && todayTime - expiresLm > lm) {
        lmExpired = true
      }
      if (!scriptsCategories[script.category]) {
        scriptsCategories[script.category] = []
      }

      scriptsCategories[script.category].push(idx)
      usedScriptsIds[script.id] = idx
      usedScripts.push(returningResult)
    })

    const storeVMData = {
      db,
      validationStatus: this.getStatusSt(status, Object.keys(db).length)
    }
    const storeVM = new Vue({ data: storeVMData })
    storeVM.$watch(
      'db',
      function (db) {
        const status = { accepted: 0, denied: 0 }
        const dbKeys = Object.keys(db)
        dbKeys.forEach((key) => {
          if (db[key] === false) {
            status.denied++
          } else if (db[key]) {
            status.accepted++
          }
        })
        this.validationStatus = self.getStatusSt(status, dbKeys.length)
      },
      { deep: true }
    )
    this.store = { state: storeVM.$data }
    this.loaded = false
    this.publicFn = {
      load: (fn) => {
        if (!this.loaded && typeof fn === 'function') {
          this.onLoadCallbacks.push(fn)
          return
        }
        fn()
      }
    }
    this.onLoadCallbacks = []
    this.categories = categories
    this.scripts = usedScripts
    this.scriptsIds = usedScriptsIds
    this.scriptsCategories = scriptsCategories
    this.dbCookieId = dbCookieId
    this.lmCookieId = lmCookieId
    this.expires = expires
    this.lmExpired = lmExpired
    this.messages = messages
    this.policies = policies
    this.currentLocale = currentLocale
    this.cookiesUses = []

    const hostname = window.location.hostname
    const domain = hostname.substring(hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1) || ''
    this.domain = domain
  }

  getCategories () {
    return this.categories
  }

  getScriptsCategory (idx) {
    return this.scriptsCategories[idx]
  }

  getScriptsCategories () {
    return this.scriptsCategories
  }

  getScriptByIdx (idx) {
    return this.scripts[idx]
  }

  getScriptById (id) {
    return this.scripts[this.scriptsIds[id]]
  }

  getScripts () {
    return this.scripts
  }

  getPolicies () {
    return this.policies
  }

  isLmExpired () {
    return this.lmExpired
  }

  acceptAndSetCookie (script, opt) {
    this.accept(script, opt)
    this.setGdprCookies()
  }

  accept (script, opt, end = true) {
    this.store.state.db[script.id] = true
    this.execute(script, opt)
  }

  execute (script, opt) {
    if (script.executed) {
      return
    }
    script.js(opt, this.currentLocale)
    script.executed = true
  }

  acceptAll () {
    this.scripts.forEach(({ script, opt }) => {
      this.accept(script, opt, false)
    })
    this.setGdprCookies()
  }

  denyAndSetCookie (script) {
    this.deny(script)
    this.setGdprCookies()
  }

  deny (script) {
    this.store.state.db[script.id] = false
    script.cookies.forEach(cookie => {
      window.$cookies.remove(cookie, null, this.domain, '.' + this.domain)
    })
  }

  denyAll () {
    this.scripts.forEach(({ script }) => {
      this.deny(script)
    })
    this.setGdprCookies()
  }

  setGdprCookies () {
    Cookies.set(this.dbCookieId, this.store.state.db, { expires: this.expires })
    Cookies.set(this.lmCookieId, (new Date()).getTime(), { expires: this.expires })
  }

  getGdprCookies () {
    return Cookies.get(this.lmCookieId)
  }

  executeAcceptedScripts () {
    this.scripts.forEach(({ script, opt }) => {
      if (this.store.state.db[script.id]) {
        this.execute(script, opt)
      }
    })
  }

  getMessages () {
    return this.messages
  }

  setLoaded () {
    this.loaded = true
    this.onLoadCallbacks.forEach(function (fn) {
      fn()
    })
  }

  setPublicFn (name, func) {
    if (this.publicFn[name] && typeof this.publicFn[name] === 'function') {
      return
    }
    this.publicFn[name] = func
  }

  executePublicFn (name, ...args) {
    switch (name) {
      case 'load':
        this.publicFn.load(...args)
        break
      default:
        this.publicFn.load(() => {
          if (!this.publicFn[name]) {
            return
          }
          this.publicFn[name](...args)
        })
    }
  }

  getStatusSt (status, dbLength) {
    return status.denied + status.accepted === 0 ? 'empty'
      : status.denied === dbLength ? 'all_denied'
        : status.accepted === dbLength ? 'all_accepted'
          : 'partial'
  }

  getCookieNumber (scriptCookies = []) {
    let idx = 0
    scriptCookies.forEach(cookieId => {
      if (Cookies.get(cookieId)) {
        idx++
      }
    })
    return idx
  }

  static install (Vue) {
    Object.defineProperty(Vue.prototype, '$gdpr', {
      get () {
        return this.$root.$options.gdpr
      }
    })
  }
}
Vue.use(VueGdpr)
export default ({ app, opt }) => {
  app.gdpr = new VueGdpr(opt)
}
