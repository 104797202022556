export default {
  id: 'facebookPixel',
  name: 'Facebook Pixel',
  category: 'tracking',
  policy: {
    fr: '<p><strong>Facebook : pixel d’audience personnalisée (audience personnalisée de votre site Internet) :</strong></p><p>Un pixel Facebook d’audience personnalisée est un morceau de code JavaScript installé sur chaque page de votre site qui collecte et transmet les données définies par l’utilisateur au programme marketing de Facebook. Nous utilisons ce service pour collecter des données liées au comportement des utilisateurs sur notre site. Parmi les informations récoltées par le pixel, on trouve la session de navigation de l’utilisateur qui est envoyée à Facebook accompagnée d’un ID haché et de l’URL visitée par l’utilisateur. Chaque utilisateur Facebook possède un ID Facebook unique, indépendamment de l’appareil utilisé, qui nous aide à surveiller les visiteurs lorsqu’ils accèdent à notre site depuis différents types d’appareils. Nous utilisons ces données à des fins commerciales et plus particulièrement pour cibler les publicités avec la plateforme de publicité Facebook.</p>'
  },
  linkKnowMore: (lang) => {
    return 'https://www.facebook.com/policies/cookies/'
  },
  linkWebsite: (lang) => {
    return 'https://www.facebook.com/business/help/952192354843755'
  },
  js: async ({ id = '#', callback = null }) => {
    // eslint-disable-next-line
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
    window.fbq('init', id)
    window.fbq('track', 'PageView')
    if (callback && typeof callback === 'function') {
      // eslint-disable-next-line
      callback({ id })
    }
  },
  cookies: ['_fbp']
}
