export default {
  others: {
    id: 'others',
    name: {
      fr: 'Autre',
      en: 'Others'
    }
  },
  tracking: {
    id: 'tracking',
    name: {
      fr: 'Mesure d\'audience',
      en: 'Tracking'
    }
  }
}
