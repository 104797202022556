import { getSiteLanguageByCode, getScript } from '@/utils/tools'
export default {
  id: 'universalAnalytics',
  name: 'Universal Analytics',
  category: 'tracking',
  policy: {
    fr: '<p><strong>Google Analytics :</strong></p><p>Ce site Internet utilise Google Analytics, un service d’analyses exploité par Google qui aide les sites à comprendre comment personnaliser leurs pages afin d’améliorer l’expérience utilisateur. Ce service utilise des cookies pour suivre vos actions, notamment le temps que vous passez sur notre site et les pages que vous visitez. Veuillez noter qu’aucune de vos données personnelles n’est collectée grâce à l’utilisation de notre compte Google Analytics, nous ne sommes donc pas en mesure d’identifier qui visite notre site. Pour en savoir plus à propos de Google Analytics, rendez-vous sur la page officielle.</p>'
  },
  linkKnowMore: (lang) => {
    return 'https://policies.google.com/technologies/types?hl=' + lang
  },
  linkWebsite: (lang) => {
    return 'https://www.google.com/intl/' + getSiteLanguageByCode({ type: 0, code: lang }) + '/analytics/'
  },
  js: function ({ ua = '#', callback = null, opt = {} }) {
    getScript('https://www.googletagmanager.com/gtag/js?id=' + ua)
    window.dataLayer = window.dataLayer || []
    const timeout = 31536000 // une année en seconde
    const defaultValue = { cookie_expires: typeof (opt.cookie_expires) !== 'undefined' ? opt.cookie_expires : timeout }
    Object.assign(opt, defaultValue)
    function gtag () { window.dataLayer.push(arguments) }
    gtag('js', new Date())
    gtag('config', ua, opt)
    var index = this.cookies.indexOf('_gat_gtag_')
    if (index > -1) {
      this.cookies.splice(index, 1)
    }
    const cookieName = '_gat_gtag_' + ua.replace(/-/g, '_')
    this.cookies.push(cookieName)
    if (callback && typeof callback === 'function') {
      // eslint-disable-next-line
      callback({ ua, gtag })
    }
  },
  cookies: ['_ga', '_gat', '_gid', '__utma', '__utmb', '__utmc', '__utmt', '__utmz', '_gat_gtag_']
}
