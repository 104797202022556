import { getScript } from '@/utils/tools'
export default async ({ token = '#', mainColor = '#333', textColor = '#57606f', bgColor = '#F7F7F7', bubbleColor = '#2095FE', language, autoOpen = false, callback = null }, locale) => {
  if (!window.jQuery) {
    console.log('no jquery')
    return
  }

  const cssLink = 'https://node.agencewebcom.com/stylesheets/chat-widget-v2.min.css'
  const jsLink = 'https://node.agencewebcom.com/javascripts/chat-widget-v2.min.js'
  const customStyle = `#wchat,#wchat-production,#wchat-production a{color:${textColor}}#chat-wrap{background-color:${bgColor}}.wchat-user-message>span{background-color:${bubbleColor}}.wchat-user-message>span:before{border-color:${bubbleColor}}.wchat-banner-desktop{background-color:${mainColor}}.wchat-banner-mobile button{background-color:${mainColor}}.wchat-user-message:before{border-top:1px solid ${bubbleColor};border-right:1px solid ${bubbleColor}}.wchat-chat-bloc.wchat-offline .wchat-message,.wchat-client-email[type='email'],.wchat-client-name[type='text'],.wchat-bloc.wchat-offline .wchat-message{border-color:${mainColor}}.wchat-submit-message[type='submit']{border:1px solid ${mainColor}}.submit-chat-mobile svg,.wchat-hide-chat svg{fill:${mainColor}}`

  getScript(jsLink, {
    callback () {
      if (!token) {
        return
      }
      window.jQuery('head')
        .append('<link rel="stylesheet" type="text/css" href="' + cssLink + '">')
        .append('<style>' + customStyle + '</style>')
      window.webc.webcChat({
        token: token,
        language: language || locale,
        autoOpen: autoOpen
        // server: 'http://localhost:3000',
        // apiRoute: 'http://360.local/web/app_dev.php/rest/wchats/encodedtokens'
      })
    }
  })
  if (callback && typeof callback === 'function') {
    // eslint-disable-next-line
    callback()
  }
}
