import { getScript } from '@/utils/tools'
export default async ({ token = '#', mainColor = '#333', language, autoOpen = false, callback = null }, locale) => {
  if (!window.jQuery) {
    console.log('no jquery')
    return
  }
  const cssLink = 'https://node.agencewebcom.com/stylesheets/chat-widget.min.css'
  const jsLink = 'https://node.agencewebcom.com/javascripts/chat-widget.min.js'
  const customStyle = `.wchat-partner-message>span,.wchat-user-message>span{border:1px solid ${mainColor}}#wchat-banner{background-color:${mainColor}}.wchat-user-message:before{border-top:1px solid ${mainColor};border-right:1px solid ${mainColor}}.wchat-partner-message:before{border-left:1px solid ${mainColor};border-bottom:1px solid ${mainColor}}.wchat-chat-bloc.wchat-offline .wchat-message,.wchat-client-email,.wchat-client-name,.wchat-bloc.wchat-offline .wchat-message{border-color:${mainColor}}.wchat-submit-message{border:1px solid ${mainColor}}`

  getScript(jsLink, {
    callback () {
      if (!token) {
        return
      }
      window.jQuery('head')
        .append('<link rel="stylesheet" type="text/css" href="' + cssLink + '">')
        .append('<style>' + customStyle + '</style>')
      window.webc.webcChat({
        token: token,
        language: language || locale,
        autoOpen: autoOpen
        // server: 'http://localhost:3000',
        // apiRoute: 'http://360.local/web/app_dev.php/rest/wchats/encodedtokens'
      })
    }
  })
  if (callback && typeof callback === 'function') {
    // eslint-disable-next-line
    callback()
  }
}
