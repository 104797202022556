import disclaimer from './en/disclaimer.html'
import privacyPolicy from './en/privacy_policy.html'

export default {
  main_text: 'The {name} site uses cookies to perform audience measurements. Click on Accept to give your consent. You can change your preferences and view <a href="{linkPolicy}" target="_blank">the Privacy Policy</a> at any time on the site',
  main_text_without_link: 'The {name} site uses cookies to perform audience measurements. Click on Accept to give your consent. You can change your preferences and view the Privacy Policy at any time on the site',
  accept_all: 'Accept all',
  deny_all: 'Deny all',
  customize: 'Customize',
  close: 'Close',
  all_services: 'Preference for all services',
  authorize: 'Authorize',
  deny: 'Deny',
  used_cookies: 'This site uses {num} cookie(s)',
  used_cookies_potential: 'This service can deposit {num} cookie (s)',
  no_cookies: 'This site does not use cookies',
  know_more: 'Learn more',
  official_website: 'See the official website',
  disclaimer,
  privacy_policy: privacyPolicy
}
