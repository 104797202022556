import disclaimer from './fr/disclaimer.html'
import privacyPolicy from './fr/privacy_policy.html'

export default {
  main_text: 'Le site {name} utilise des cookies pour réaliser des mesures d’audience. Cliquez sur Accepter pour donner votre consentement.<br>Vous pouvez modifier vos préférences et consulter la <a href="{linkPolicy}" target="_blank">Politique de confidentialité</a> à tout moment sur le site',
  main_text_without_link: 'Le site {name} utilise des cookies pour réaliser des mesures d’audience. Cliquez sur Accepter pour donner votre consentement. Vous pouvez modifier vos préférences et consulter la Politique de confidentialité à tout moment sur le site',
  accept_all: 'Accepter tout',
  deny_all: 'Refuser tout',
  customize: 'Personnaliser',
  close: 'Fermer',
  all_services: 'Préférence pour tous les services',
  authorize: 'Autoriser',
  deny: 'Interdire',
  used_cookies: 'Ce site utilise {num} cookie(s)',
  used_cookies_potential: 'Ce service peut déposer {num} cookie(s)',
  no_cookies: 'Ce site n\'utilise aucun cookie',
  know_more: 'En savoir plus',
  official_website: 'Voir le site officiel',
  disclaimer,
  privacy_policy: privacyPolicy
}
