import Vue from 'vue'
import VueI18n from 'vue-i18n'
import merge from 'lodash.merge'
import fr from '@/messages/fr'
import en from '@/messages/en'

Vue.use(VueI18n)

export default ({ app, locale = 'en' }) => {
  const messages = merge({}, { fr, en }, app.gdpr.getMessages())
  // Create VueI18n instance with options
  app.i18n = new VueI18n({
    locale: locale,
    messages
  })
}
