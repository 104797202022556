const langTypes = [
  {
    fr: 'fr_fr',
    en: 'en_en'
  },
  {
    fr: 'fr-fr',
    en: 'en-en'
  }
]
export const getSiteLanguageByCode = ({ type, code = 'en' }) => {
  if (!type || !langTypes[type]) {
    return code
  }
  const langTypeKeys = Object.keys(langTypes[type])
  if (!langTypeKeys.length) {
    return code
  }
  if (!langTypes[type][code]) {
    return langTypes[type].en || langTypes[type][langTypeKeys[0]]
  }
  return langTypes[type][code]
}

export const getScript = (source, { async = 1, callback = null } = {}) => {
  if (!source) {
    return
  }
  let script = document.createElement('script')
  const prior = document.getElementsByTagName('script')[0]
  /* eslint-disable semi */
  script.async = async;
  script.onload = script.onreadystatechange = (_, isAbort) => {
    if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
      script.onload = script.onreadystatechange = null
      script = undefined

      if (!isAbort && typeof callback === 'function') { callback() }
    }
  }
  script.src = source
  prior.parentNode.insertBefore(script, prior)
}

export const getDefaultValue = (objOrString, defValue = '', defKey = null) => {
  defValue = !defValue && defValue !== '' ? '' : defValue
  if (!objOrString) {
    return defValue
  }
  const isNotEmptyObject = typeof objOrString === 'object'
  if (!isNotEmptyObject) {
    return typeof objOrString === 'string' ? objOrString : defValue
  }
  const objOrStringKeys = Object.keys(objOrString)
  if (!objOrStringKeys.length) {
    return defValue
  }
  return defKey && objOrString[defKey] ? objOrString[defKey] : objOrString[objOrStringKeys[0]]
}
