<template>
  <div id="wc-gdpr-widget">
    <component
      :is="template"
    >
    </component>
  </div>
</template>
<script>
export default {
  name: 'App',
  computed: {
    template () {
      return () => import(`@/templates/${this.$template.name}`)
    }
  },
  mounted () {
    this.template().then(comp => {
      this.$nextTick(() => {
        this.$gdpr.executeAcceptedScripts()
        this.$gdpr.setPublicFn('disclaimer', (opt = {}, item = 'wc-gdpr-disclaimer') => {
          if (typeof opt !== 'string') {
            const defOpt = {
              url: '',
              society: '',
              societyType: '',
              city: '',
              societyId: '',
              address: '',
              director: '',
              photoAuthorLink: '',
              photoAuthorName: '',
              email: '',
              phone: '',
              addresss: ''
            }
            opt = { ...defOpt, ...opt }
            const container = window.document.getElementById(item)
            if (container) {
              container.innerHTML = this.$t('disclaimer', opt)
            }
          } else {
            const container = window.document.getElementById(item)
            if (container) {
              container.innerHTML = opt
            }
          }
        })
        this.$gdpr.setPublicFn('privacy', (item = 'wc-gdpr-policy') => {
          const container = window.document.getElementById(item)
          if (container) {
            let html = this.$t('privacy_policy')
            if (typeof this.$gdpr.getPolicies() !== 'string') {
              this.$gdpr.getPolicies().forEach((policy) => {
                html += this.$t(`policies.${policy}`)
              })
            } else {
              html = this.$gdpr.getPolicies()
            }
            container.innerHTML = html
          }
        })
        this.$gdpr.setLoaded()
      })
    })
  }
}
</script>
